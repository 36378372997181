window._ = require('lodash');

try {
  window.Popper = require('@popperjs/core').default;
  window.$ = window.jQuery = require('jquery');

  window.bootstrap = require('./../../node_modules/bootstrap/dist/js/bootstrap.bundle.js');
} catch (e) {}

// window.Vue = require('vue/dist/vue.js');
// window.axios = require('axios');
// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.toastr = require('toastr/toastr');

window.get_params = function(param, url='') {
  var queryString;
  if(url=='') {
    queryString = window.location.search;
  } else {
    var surl = new URL(url);
    queryString = surl.search;
  }
  const urlParams = new URLSearchParams(queryString);
  if(urlParams.has(param)) {
    return urlParams.get(param);
  } else {
    return '';
  }
}
window.sid = get_params('sid');
if(sid !== null && sid !== undefined && sid !== '') {
  const hyperlinks = document.querySelectorAll("a");
  for(let i=0; i<hyperlinks.length; i++) {
    //if( (get_params('sid', hyperlinks[i].href)=='') && ((hyperlinks[i].href.indexOf('modules.php') !== -1) || (hyperlinks[i].href.indexOf(location.hostname) !== -1) ) ) {
    if( get_params('sid', hyperlinks[i].href)=='' && hyperlinks[i].href.indexOf(location.hostname) !== -1)   {
      if(hyperlinks[i].href.indexOf('?') !== -1) {
        hyperlinks[i].href += '&sid=' + sid;
      } else {
        hyperlinks[i].href += '?sid=' + sid;
      }
    }
  }
}
const dates = document.querySelectorAll(".date");
const today = new Date();
const now = new Date(today.getFullYear() + '-' + ('0' + (today.getMonth()+1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2));
// console.log(now);
for(let i=0; i<dates.length; i++) {
  let d;
  let att = dates[i].getAttribute('data-date');
  if ( att !== null ) {
    d = new Date(att);
  } else {
    d = new Date(dates[i].innerText.slice(0,10));
  }
  if( d !== undefined && isNaN(d) === false) {
    let diff = Math.floor( (now-d)/(86400*1000)   );
    // console.log(diff);
    let bg = '';
    if(diff != 0 && isNaN(diff) === false) {
      if(diff>729) {
        bg = 'danger';
      } else if(diff>364) {
        bg = 'warning';
      } else if (diff > 60) {
        bg = 'info';
      } else {
        bg = 'secondary';
      }
      dates[i].innerHTML += ' <span class="badge bg-' + bg + '">' + diff + '天前';
      dates[i].classList.add("text-" + bg);
      if(diff < 8) {
        dates[i].classList.add("text-week");
      }
    } else {
      if( diff == 0) {
        // dates[i].parentElement.classList.add("text-success");
        dates[i].classList.add("text-success");
        dates[i].classList.add("text-week");
      }
    }
  } else {
    dates[i].classList.add("text-danger");
  }
}
